.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s ease-in-out;
  }
}

.App-header {
  background-color:  #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

table {
  font-size: calc(10px + 1.5vmin);
}
thead td {
  font-weight: bold;
}
h1 {
  margin-top: 0;
  color:#f9eb0a;
  font-size: calc(10px + 5vmin);
}
h2 {
  font-size: calc(10px + 2vmin);
}
td {
  padding-left: 15px;
  padding-right: 15px;
}

button {
  text-transform: uppercase;
  background-color: #f9eb0a;
  border: 0;
  font-size: calc(10px + 2vmin);
  cursor: pointer;
  padding:20px;
}

.assembly-point-selector-wrapper{
  font-size: calc(10px + 0.5vmin);
  margin-bottom: 10px;
  height: 45px;
  color:#000;
  font-weight: 500;
  width: calc(100vw - 10px);
}


form #error {
  color:#F00;
}
form p, form input {
  margin: 10px 0 10px 0;
}
form input {
  display: block;
  width: 100%;
  font-size: calc(10px + 2vmin);
  text-align: center;
}
form button {
  margin-top: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}